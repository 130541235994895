'use strict';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// Main navigation toggle

const mainNavToggle = document.querySelector('.toggle-main-nav');

function mainNav() {
  const mainNav = document.querySelector('.main-nav');
  mainNav.classList.toggle('is-active');
}

mainNavToggle.addEventListener('click', mainNav);

// Image gallery

const imgGalleries = document.querySelectorAll('.img-gallery');

imgGalleries.forEach(function(imgGallery) {
  
  const imgGallerySlides = tns({
    container: imgGallery,
    mode: 'gallery',
    items: 1,
    slideBy: 'page',
    autoplay: false,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: false,
    prevButton: '.img-gallery__control--prev',
    nextButton: '.img-gallery__control--next',
  });

});

// Referral form

const referralFields = document.querySelectorAll('.referral-type input[type="radio"]');
const referringSomebodyElse = referralFields[1];
const referringMyself = referralFields[0];

function showRefereeFields() {
  const refereeFields = document.querySelectorAll('[data-referral="referee"]');
  const referralFields = document.querySelectorAll('[data-referral="referral"]');

  if(referringMyself.checked){
    refereeFields.forEach(refereeField => {
      refereeField.classList.add('js-is-hidden');
    });
    referralFields.forEach(referralField => {
      referralField.classList.remove('js-is-hidden');
    });
  }

  if(referringSomebodyElse.checked) {
    refereeFields.forEach(refereeField => {
      refereeField.classList.remove('js-is-hidden');
    });
    referralFields.forEach(referralField => {
      referralField.classList.add('js-is-hidden');
    });
  }

}

if(referringSomebodyElse) {
  referringSomebodyElse.addEventListener('click', showRefereeFields);
}

if(referringMyself) {
  referringMyself.addEventListener('click', showRefereeFields);
}

var donationForm = document.getElementById('donation-form');

if(donationForm) {
  donationForm.addEventListener('submit', function(e) {
    var fixedAmountInput = document.querySelector('input[name="fixed-amount"]:checked');
    if(fixedAmountInput) {
      var fixedAmount = fixedAmountInput.value;
    }

    var customAmount = document.querySelector('input[name="donation-amount"]').value;

    if(!fixedAmount && customAmount == '') {
      e.preventDefault();
      document.getElementById('donation-error').style.display = 'block';
    } else {
      document.getElementById('donation-error').style.display = 'none';
    }
  });
}

// Pre-filling today's date on booking form

const today = new Date().toISOString().substr(0, 10);
const dateFields = document.querySelectorAll('.wpcf7-date');

dateFields.forEach(dateField => {
  dateField.value = today;
});

// Blog filters

const postFeed = document.querySelector('.blog-post-feed .link-blocks');
const targetSelector = '.mix';

document.addEventListener("DOMContentLoaded", function(){
  if (postFeed) {
    const mixer = mixitup(postFeed, {
      animation: {
        enable: false,
        animateResizeContainer: false
      },
      pagination: {
        limit: 12,
        hidePageListIfSinglePage: true,
        loop: true
      },
      selectors: {
        pageList: '.pagination',
        target: targetSelector
      },
      templates: {
        pagerPrev:
          '<button class="${classNames} text-btn" data-page="prev" aria-label="Previous page">Previous</button>',
        pagerNext:
          '<button class="${classNames} text-btn" data-page="next" aria-label="Next page">Next</button>',
        pager:
          '<button class="${classNames} text-btn" data-page="${pageNumber}">${pageNumber}</button>'
      }
    });
  }
});

if(typeof URLSearchParams === "function"){
  
  const urlParams = new URLSearchParams(window.location.search);
  const room = urlParams.get('room');

  if(room) {
    const selectedRoom = document.querySelector('input[data-id="' + room + '"]');
    selectedRoom.checked = 'checked';
  }
}

// Referrral form switcher

const referralFormToggles = document.querySelectorAll('.referral-form-toggle');

referralFormToggles.forEach(function(tabTriggerBtn, index){
    tabTriggerBtn.addEventListener('click', function(){
				// tabTriggers in this instance is where the dash-style is converted to camelCase - so data-tab-trigger converts to tabTriggers
        var currentTabData = document.querySelector('.referral-form[data-form="' + this.dataset.formType + '"]');

        // remove classess
        document.querySelector('.referral-form.is-visible').classList.remove('is-visible');
        document.querySelector('.referral-form-toggle.is-active').classList.remove('is-active');
        
        // add classes
        currentTabData.classList.add('is-visible');
        this.classList.add('is-active');
    });
});